.businessCard {
   @include f(flex-start, flex-start);
   flex-direction: column;
   border-radius: 20px 20px 20px 20px;
   box-shadow: 1px 3px 8px 0px $iconGray, -1px 3px 8px 0px $iconGray;
   min-width: 0;
   width: 100%;

   &__img-box {
      width: 100%;
      background-color: $darkGray;
      height: 220px;
      border-radius: 20px 20px 0px 0px;
      position: relative;
   }

   &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px 20px 0px 0px;
   }

   &__stock {
      z-index: 2;
      position: absolute;
      top: 20px;
      left: 20px;
      background-color: $orange;
      padding: 10px;
      border-radius: 10px;
      color: white;
      border: none;
      box-shadow: none;
      font-size: 12px;
   }

   &__likeIcon-box {
      position: absolute;
      bottom: -20px;
      right: 20px;
      height: 40px;
      width: 40px;
      background-color: white;
      border-radius: 50%;
      @include f(center, center);
   }

   &__content {
      padding: 25px 15px 25px 15px;
      max-width: 100%;
   }

   &__title {
      font-size: 20px;
      margin-bottom: 5px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5;
      max-height: 3em;
   }

   &__text-box {
      display: flex;

      &_vertical {
         flex-direction: column;
         margin-bottom: 5px;
         min-height: 23px;
      }
   }

   &__text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5;
      max-height: 3em;
      font-size: 14px;
      font-weight: 500;

      &_open::after {
         content: '';
         display: inline-block;
         height: 6px;
         width: 6px;
         border-radius: 50%;
         background-color: rgb(0, 182, 0);
         position: relative;
         right: -5px;
         top: -2px;
      }

      &_closed::after {
         content: '';
         display: inline-block;
         height: 6px;
         width: 6px;
         border-radius: 50%;
         background-color: rgb(244, 67, 54);
         position: relative;
         right: -5px;
         top: -2px;
      }

      &__coming::after {
         content: '';
         display: inline-block;
         height: 6px;
         width: 6px;
         border-radius: 50%;
         background-color: rgb(255, 171, 0);
         position: relative;
         right: -5px;
         top: -2px;
      }

      &_p {
         margin: 20px 0px;
         display: -webkit-box;
         -webkit-box-orient: vertical;
         -webkit-line-clamp: 2;
         line-clamp: 2;
         overflow: hidden;
         text-overflow: ellipsis;
         line-height: 1.5;
         max-height: 3em;
         min-height: 3em;
      }
   }

   &_gray {
      color: $iconGray;
      display: inline;
      margin-right: 10px;
   }

   &_bottom {
      display: inline-flex;
      align-items: center;
      margin-right: 20px;
      font-size: 14px;
      font-weight: 500;
   }

   &_bottom_paid {
      &::before {
         content: url("../../images/icons/paid.svg");
         display: inline-block;
         width: 20px;
         height: 20px;
         background-size: contain;
         background-repeat: no-repeat;
         margin-right: 10px;

      }
   }

   &_bottom_comments {
      &::before {
         display: inline-block;
         content: url("../../images/icons/comment.svg");
         width: 20px;
         height: 20px;
         background-size: contain;
         background-repeat: no-repeat;
         background-position: center;
         margin-right: 10px;
      }
   }

   &__hr {
      width: 100%;
      border: 1px solid rgb(246, 246, 246);
   }

   &__bottom {
      width: 100%;
      padding: 15px;
      @include f (space-between, center)
   }

   &__button {
      border-radius: 10px;
      background-color: transparent;
      color: white;
      padding: 10px 15px;
      font-size: 12px;
      position: relative;
      width: 95px;
      height: 36px;

      span {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         display: block;
         z-index: 3;
         transition: left 0.3s;
      }

      &:hover span {
         left: 45%;
      }

      &::before {
         content: '';
         background-color: $orange;
         border-radius: 10px;
         width: 95px;
         height: 36px;
         position: absolute;
         top: 0;
         left: 0;
         display: block;
         -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 50%, 100% 100%, 0% 100%);
         clip-path: polygon(0% 0%, 100% 0, 100% 50%, 100% 100%, 0% 100%);
         transition: clip-path 0.4s cubic-bezier(0.2, 1, 0.8, 1), -webkit-clip-path 0.4s cubic-bezier(0.2, 1, 0.8, 1);
         z-index: 2;
      }

      &:hover::before {
         background: $orange;
         -webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
         clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
      }
   }
}

.pagination {
   &__container {
      @include f(center, center);
      margin-top: 20px;
   }

   &__item {
      font-family: "Segoe UI Regular";
      border: solid 1px rgb(246, 246, 246);
      padding: 12px 0px;
      width: 40px;
      text-align: center;
      user-select: none;
      cursor: pointer;
      font-weight: 500;
      font-size: 12px;
      box-sizing: border-box;

      &_active {
         background-color: $orange;
         color: white;
      }

      &_prev {
         border-radius: 50% 0px 0px 50%;
      }

      &_next {
         border-radius: 0px 50% 50% 0px;
      }

      &_disabled {
         display: none;
      }
   }
}