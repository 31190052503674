.add {
   &__container {
      width: 100% !important;
   }

   &__slide {
      border-radius: 10px;
      @include f(center, center);
   }

   &__image {
      width: 100%;
      border-radius: 10px;
      object-fit: contain;
   }
}

.infoMobileApp {
   &__container {
      @include f(space-between, center);
      margin-bottom: 30px;
   }

   &__title {
      font-size: 34px;
      font-weight: 900;
      margin-bottom: 10px;
      font-family: "Raleway", sans-serif;
      word-wrap: break-word;
   }

   &__text {
      font-weight: 500;
      width: 580px;
      margin-bottom: 25px;
      font-family: "Raleway", sans-serif;
   }

   &__buttons-box {
      width: 40%;
      height: 128.13px;
      display: grid;
      grid-template-columns: 57% 43%;
      grid-template-rows: 1fr 1fr;
      gap: 20px;
   }

   &__button {
      background-color: black;
      border-radius: 10px;
      border: solid 2px rgb(76, 79, 95);
      @include f(flex-start, center);
      padding: 5px 10px;
      transition: background-color 0.3s, transform 0.1s;

      &:hover {
         background-color: rgba($color: #000000, $alpha: 0.8);
      }

      &_big {
         @include f(center, center);
         padding: 10px 10px;
         background-color: white;
         grid-row: 1/3;
         grid-column: 2/3;
         box-shadow: 1px 3px 8px 0px $iconGray, -1px 3px 8px 0px $iconGray;
         border: none;
         width: 128px;

         &:hover {
            background-color: white;
         }
      }
   }

   &__img {
      height: 350px;
      object-fit: contain;
      position: relative;
      top: 10px;
      left: -50px;
   }
}

.info {
   font-family: 'Raleway';
   margin-bottom: 50px;

   &__h4 {
      font-size: 20px;
      margin-bottom: 5px;
   }

   &__text {
      font-size: 12px;
      margin-left: 15px;
      margin-bottom: 5px;

      &_first {
         margin-left: 10px;
      }
   }

   &__h5 {
      font-size: 12px;
      margin-left: 10px;
      margin-bottom: 5px;
   }

   summary {
      list-style-type: none;
   }

   summary::after {
      content: url("../../images/icons/plus16.svg");
      margin-left: auto;
      margin-right: 5px;
      height: 100%;
      transition: transform 0.3s ease;
      cursor: pointer;
   }

   details[open] summary::after {
      transform: rotate(45deg);
   }
}

.content {
   margin: 30px 0px 40px 0px;

   &__container {
      margin: 0px 5px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 33px;
      align-items: start;
   }

   &__loading {
      margin: 0px 5px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 33px;
      align-items: start;
   }

   &__title-box {
      @include f(space-between, center);
      margin-bottom: 30px;
   }

   &__title {
      font-size: 28px;
   }

   &__city {
      color: $orange;
   }

   &__count {
      font-family: "Raleway", sans-serif;
      margin-left: 30px;
      font-size: 16px;
      color: $iconGray;
   }

   &__viewMapBtn {
      background-color: $blue;
      border-radius: 5px;
      color: white;
      padding: 10px 20px;
      font-size: 14px
   }
}