.timetable {
    font-family: "Segoe UI";
    position: relative;

    &__webLinkBtn {
        position: absolute;
        right: 15px;
        top: 10px;
        background-color: #FF922E;
        border-radius: 10px;
        padding: 10px 15px;
        color: white;
        font-size: 13px;
    }

    &__item {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #E6E6E6;
        padding: 10px 0px;
    }

    &__content {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    &__dateBox {
        display: flex;
        flex-direction: column;
        width: 105px;
        justify-content: flex-start;
        flex-shrink: 0;
    }

    &__dayMonth-box {
        position: relative;
    }

    &__day {
        font-size: 44px;
        font-weight: 500;
        line-height: 46px;
    }

    &__month {
        font-size: 16px;
        font-weight: 700;
        position: absolute;
        top: 0px;
        margin: 5px 0px 0px 5px;
    }

    &__dayOfWeek {
        color: #999999;
        font-size: 14px;
        font-weight: 400;
    }

    &__placeBox {
        display: flex;
        flex-direction: column;
    }

    &__place {
        font-size: 14px;
    }

    &__address {
        font-size: 12px;
        color: #999999;
        margin-bottom: 10px;
    }

    &__timeBox {
        display: flex;
    }

    &__timeItem {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__time {
        display: inline-block;
        font-size: 13px;
        border: 1px solid #FF922E;
        padding: 5px 10px;
        border-radius: 5px;
    }

    &__price {
        font-size: 10px;
        font-weight: 400;
    }
}

.posterPage {
    max-width: 1024px;

    &__firstLine {
        @include f(space-between, flex-start);
        width: 100%;
        margin-bottom: 20px;
        gap: 10px;
    }

    &__title {
        font-size: 20px;
        margin-bottom: 5px;
    }

    &__text {
        font-size: 14px;
        font-weight: 500;

        &_gray {
            color: $iconGray;
            display: inline;
        }

        &_p {
            max-width: 500px;
        }
    }

    &__firstLine-btnBox {
        @include f(flex-start, flex-start);
    }

    &__button {
        height: 50px;
        width: 50px;
        border-radius: 15px;
        box-shadow: 1px 3px 8px 0px $iconGray, -1px 3px 8px 0px $iconGray;
        margin-right: 10px;
        @include f(center, center);
        background-color: $backGray;
        transition: background-color 0.3s;

        &:hover {
            background-color: white;
        }
    }

    &__secondLine {
        @include f(space-between, flex-start);
        margin-bottom: 20px;
    }

    &__link {
        text-decoration: underline dotted;
        text-underline-offset: 5px;
    }

    &__img-box {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 2;
        max-width: 695px;
        background-color: $darkGray;
        max-height: 390px;
        border-radius: 16px;
        position: relative;
    }

    &__stock {
        z-index: 2;
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: $orange;
        padding: 10px;
        border-radius: 10px;
        color: white;
        border: none;
        box-shadow: none;
        font-size: 12px;
    }

    &__img {
        width: 100%;
        border-radius: 16px;
        height: 100%;
        object-fit: cover;
    }

    &__widgets {
        width: auto;
        display: grid;
        grid-template-columns: 70% 30%;
        grid-template-rows: auto;
        gap: 30px;
        position: relative;
        margin-bottom: 50px;
        padding-right: 30px;

        &:first-child {
            max-height: 150px;
        }

    }

    &__widgetsRight {
        grid-row: 1/3;
        grid-column: 2/3;
    }
}