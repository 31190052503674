@keyframes fadeIn {
   from {
      opacity: 0;
   }

   to {
      opacity: 1;
   }
}

.myInput {
   display: block;
   width: 100%;
   padding: 10px 0px 10px 15px;
   background-color: transparent;
   border: 1px solid rgb(205, 206, 211);
   border-radius: 5px;
   margin: 10px auto;
   animation: fadeIn 2s;

   &::placeholder {
      color: rgb(205, 206, 211);
   }
}