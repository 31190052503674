@media (max-width: 1024px) {
    .add {
        &__container {
            padding: 0px 10px;
        }
    }

    .info {
        padding: 0px 10px;
    }

    .infoMobileApp {
        &__content {
            padding: 0px 20px 0px 10px;
        }

        &__img {
            display: none;
        }
    }

    .content {
        margin: 20px 0px 30px 0px;

        &__container {
            margin: 0px;
            padding: 0px 10px;
            grid-template-columns: repeat(auto-fill, minmax(239px, 1fr));
            grid-gap: 15px;
        }

        &__loading {
            margin: 0px;
            padding: 0px 10px;
            grid-template-columns: repeat(auto-fill, minmax(239px, 1fr));
            grid-gap: 15px;
        }

        &__title-box {
            padding: 0px 10px;
        }

    }
}

@media (max-width: 768px) {
    .info {
        &__h4 {
            font-size: 15px;
            margin-bottom: 5px;
        }

        &__text {
            font-size: 12px;
            margin-left: 15px;
            margin-bottom: 5px;

            &_first {
                margin-left: 10px;
            }
        }

        &__h5 {
            font-size: 12px;
            margin-left: 10px;
            margin-bottom: 5px;
        }

        summary::after {
            content: url("../../images/icons/plus12.svg");
        }
    }

    .infoMobileApp {
        &__container {
            margin-bottom: 20px;
        }

        &__text {
            font-size: 12px;
            width: auto;
            margin-bottom: 20px;
        }

        &__title {
            font-size: 26px;
        }

        &__buttons-box {
            max-width: 60%;
            height: auto;
            gap: 10px;
        }

        &__button {
            &_big {
                width: auto;
            }
        }
    }

    .content {
        margin: 15px 0px 25px 0px;

        &__container {
            padding: 0px 5px;
            grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
            position: relative;
        }

        &__loading {
            padding: 0px 5px;
            grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        }

        &__title-box {
            margin-bottom: 20px;
        }

        &__title {
            font-size: 26px;
        }

        &__count {
            margin-left: 20px;
            font-size: 14px;
        }
    }
}

@media (max-width: 699px) {
    .content {
        &__container_justifyLast>*:last-child {
            position: relative;
            left: 50%;
            bottom: 0;
            transform: translateX(7.5px);
        }
    }
}

@media (max-width: 474px) {
    .content {
        &__container_justifyLast>*:last-child {
            position: inherit;
            left: 0;
            bottom: 0;
            transform: translateX(0px);
        }
    }

}

@media (max-width: 480px) {
    .info {
        margin-bottom: 20px;
        padding: 0px 5px;

        &__h4 {
            font-size: 15px;
            margin-bottom: 5px;
        }

        &__text {
            font-size: 12px;
            margin-left: 10px;
            margin-bottom: 5px;

            &_first {
                margin-left: 5px;
            }
        }

        &__h5 {
            font-size: 12px;
            margin-left: 5px;
            margin-bottom: 5px;
        }
    }

    .infoMobileApp {
        &__title {
            font-size: 18px;
            margin-bottom: 5px;
        }

        &__text {
            margin-bottom: 10px;
        }

        &__buttons-box {
            width: 65%;
        }

        &__button {
            &_big {
                width: auto;
            }
        }
    }

    .content {
        margin: 10px 0px 20px 0px;

        &__container {
            padding: 0px 10px;
        }

        &__loading {
            padding: 0px 10px;
        }

        &__title-box {
            padding: 0px 10px;
        }

        &__title-box {
            margin-bottom: 15px;
        }

        &__title {
            font-size: 24px;
        }

        &__count {
            margin-left: 15px;
            font-size: 12px;
        }
    }
}