@media (max-width: 1024px) {
    .infoAboutCompany {
        margin-top: 40px;
        margin-bottom: 40px;
        grid-template-columns: 2fr minmax(100px, 1.5fr);
        grid-gap: 10px;
        padding: 0px 10px;

        &__block {
            &_white {
                padding: 20px;
                position: relative;
            }

            &_blue {
                padding-right: 40px;
            }

            &_orange {
                padding: 20px;
            }
        }

        &__text {
            font-size: 28px;
            margin-bottom: 15px;

            &_blue {
                font-size: 36px;
                margin-bottom: 15px;
            }

            &_white {
                margin-right: 20px;
                font-size: 18px;
            }
        }

        &__list-item {
            font-size: 14px;
            margin-bottom: 10px;
        }

        &__img {
            position: relative;
            top: 0;
            bottom: 0px;
            height: 100%;
            object-fit: fill;
            max-width: 100%;
        }

        &__icons {
            margin-bottom: 15px;
        }

        &__icon {
            margin-right: 10px;
        }

        &__paragrath {
            margin-bottom: 15px;
            font-size: 14px;
        }
    }

    .howItWorks {
        margin-bottom: 40px;
        padding: 0px 10px;

        &__title {
            text-align: center;
        }

        &__hr {
            margin-bottom: 30px;
        }

        &__bloks {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, auto);
            gap: 20px;
        }

        &__block {
            &_blue {
                padding: 30px 5px 0px 5px;
                position: relative;
            }

            &_white {
                position: relative;

                img {
                    max-height: 90%;
                    position: absolute;
                    left: auto;
                    right: 30px;
                    bottom: 0px;
                    transform: translateX(-0%);
                }
            }

            &_orange {
                padding-top: 30px;
                position: relative;

                p {
                    margin-bottom: 20px;
                }
            }
        }

        &__span {
            margin-left: 30px;
            margin-bottom: 30px;
            font-size: 22px;
        }

        &__paragrath {
            margin-left: 30px;
            margin-bottom: 40px;
            font-size: 22px;
        }

        &__img {
            max-height: 90%;
            position: absolute;
            right: 30px;
            bottom: 0px;
        }

        &__button {
            margin-top: 10px;
            width: 175px;
            padding: 15px 0px;
            font-size: 20px;
        }
    }

    .opportunities {
        margin-bottom: 40px;
        padding: 20px 10px;

        &__title {
            text-align: center;
        }

        &__hr {
            margin-bottom: 30px;
        }

        &__block {
            position: relative;
            padding: 25px 20px 0px 20px;

            &_big {
                grid-column: span 2;
                padding: 25px 35px 15px 35px;
            }

            &_3 {
                h4 {
                    margin-top: 0px;
                }

                hr {
                    margin-bottom: 10px;
                }
            }

            &_5 {
                padding: 25px 30px 15px 30px;
            }
        }

        &__block-title {
            font-size: 16px;
            margin-bottom: 10px;
        }

        &__block-hr {
            margin-bottom: 10px;
        }

        &__block-p {
            font-size: 12px;
            line-height: 22px;
        }

        &__block-icon {
            position: relative;
            object-fit: contain;
            bottom: 0px;
            left: 100%;
            transform: translateX(-100px);

            &_1 {
                bottom: -15px;
                width: 100px;
                transform: translateX(-120px);
                width: 89px;
            }

            &_2 {
                transform: translateX(-80px);
                bottom: 0px;
                width: 71px;
            }

            &_3 {
                bottom: 10px;
                width: 76px;
            }

        }
    }

    .invitation {
        padding: 0px 10px;
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) 1.5fr;
        grid-template-rows: repeat(2, minmax(100px, 1fr));
        gap: 10px;
        margin-bottom: 30px;

        &__block {
            @extend %blockAboutApp;
            border-radius: 40px;

            &_img {
                &_1 {
                    background-size: auto auto;
                }

                &_2 {
                    background-size: auto auto;
                }

                &_3 {
                    background-size: auto auto;
                }

                &_4 {
                    background-size: auto auto;
                }
            }
        }

        &__span {
            font-size: 16px;
        }

        &__title {
            font-size: 20px;
            margin-bottom: 20px;
        }

        &__paragrath {
            font-size: 18px;
        }

        &__img {
            width: auto;
            height: 161px;
            object-fit: contain;
            position: absolute;
            top: 20px;
            right: 30px;
        }
    }

    .ratings {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        padding: 10px 10px;

        &__block {
            flex-direction: column;

            &_transparent {
                max-height: 50%;
                margin-bottom: 20px;
            }

            &_qrAndLogo {
                display: flex;
                justify-content: center;
                gap: 20px;
                max-height: 45%;
            }
        }

        &__count {
            margin: 20px;
            font-size: 62px;
            margin-bottom: 35px;
        }

        &__paragrath {
            margin-bottom: 10px;
            font-size: 18px;
        }

        &__img {
            max-height: 250px;
        }

        &__qr {
            width: auto;
            max-width: 230px;
            position: inherit;
            height: auto;
        }

        &__qr-text {
            font-size: 18px;
        }

        &__qr-img {
            width: auto;
            max-height: 100%;
        }

        &__logo {
            height: auto;
            width: auto;
            max-width: 40%;
            position: inherit;
        }
    }

    .question {
        margin-bottom: 40px;
        padding: 0px 10px;

        &__title {
            font-size: 42px;
            font-weight: 700;
            padding-bottom: 50px;
            border-bottom: 1px solid rgb(237, 237, 237);
            width: 100%;
            text-align: center;
            margin-bottom: 15px;
        }

        &__hr {
            border: 1px solid rgb(237, 237, 237);
            margin: 15px 0px;
        }

        summary {
            font-family: "Raleway", sans-serif;
            list-style: none;
            font-size: 24px;
            font-weight: 600;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        summary::after {
            content: url("../../images/icons/plus.svg");
            margin-left: auto;
            margin-right: 5px;
            transition: transform 0.3s ease;
        }

        details[open] summary::after {
            transform: rotate(45deg);
        }

        details[open] summary {
            margin-bottom: 10px;
        }

        p {
            font-size: 16px;
            font-weight: 500;
        }
    }
}

@media (max-width: 768px) {
    .infoAboutCompany {
        margin-top: 20px;
        margin-bottom: 20px;
        grid-template-columns: 2fr minmax(100px, 1.5fr);
        grid-template-rows: repeat(2, auto);

        &__block {
            &_white {
                padding: 20px;
                grid-column: span 2;
            }

            &_blue {
                display: none;
            }

            &_orange {
                padding: 20px;
                grid-column: span 2;
            }
        }

        &__text {
            font-size: 24px;
            margin-bottom: 15px;

            &_blue {
                font-size: 32px;
                margin-bottom: 15px;
            }

            &_white {
                margin-right: 20px;
                font-size: 18px;
            }
        }

        &__list-item {
            font-size: 12px;
            margin-bottom: 10px;
        }

        &__img_mobile {
            display: block;
            position: absolute;
            bottom: 0px;
            right: 40px;
            height: 80%;
            max-width: 120px;
        }

        &__icons {
            margin-bottom: 15px;
        }

        &__icon {
            margin-right: 10px;
        }

        &__paragrath {
            margin-bottom: 15px;
            font-size: 14px;
        }

        &__button {
            font-size: 16px;
        }
    }

    .howItWorks {
        margin-bottom: 20px;
        padding: 0px 10px;

        &__title {
            margin-bottom: 10px;
            font-size: 24px;
        }

        &__hr {
            margin-bottom: 20px;
        }

        &__block {
            &_blue {
                padding: 30px 5px 0px 5px;
                position: relative;
            }

            &_white {
                @include f(space-between, flex-start);
                background-color: rgb(235, 235, 235);
                color: black;
                flex-direction: column;
                padding-bottom: 0px;
                padding-top: 30px;
                position: relative;

                img {
                    max-height: 90%;
                    position: absolute;
                    left: auto;
                    right: 30px;
                    bottom: 0px;
                    transform: translateX(-0%);
                }

                span {
                    margin-left: 0px;
                }

                p {
                    margin-left: 0px;
                }

            }

            &_orange {
                padding-top: 30px;
                position: relative;

                p {
                    margin-left: 0px;
                    margin-bottom: 20px;
                }

                img {
                    max-height: 89%;
                }
            }
        }

        &__span {
            margin-left: 20px;
            margin-bottom: 20px;
            font-size: 18px;
        }

        &__paragrath {
            margin-left: 20px;
            margin-bottom: 40px;
            font-size: 16px;
        }

        &__button {
            margin-top: 10px;
            width: 175px;
            padding: 15px 0px;
            font-size: 16px;
        }
    }

    .opportunities {
        margin-bottom: 40px;
        padding: 20px 10px;

        &__title {
            font-size: 24px;
            margin-bottom: 10px;
        }

        &__hr {
            margin-bottom: 20px;
        }

        &__bloks {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(5, auto);
            gap: 10px;
        }

        &__block {
            position: relative;
            padding: 25px 20px 0px 20px;

            &_big {
                grid-column: span 1;
                padding: 25px 20px 25px 20px;
            }

            &_3 {
                h4 {
                    margin-top: 0px;
                }

                hr {
                    margin-bottom: 10px;
                }
            }

            &_5 {
                padding: 25px 20px 25px 20px;
            }
        }

        &__block-title {
            font-size: 16px;
            margin-bottom: 10px;
        }

        &__block-hr {
            margin-bottom: 10px;
        }

        &__block-p {
            font-size: 12px;
            line-height: 22px;
        }

        &__block-icon {
            position: relative;
            object-fit: contain;
            bottom: 0px;
            left: 100%;
            transform: translateX(-100px);

            &_1 {
                bottom: 0px;
                width: 100px;
                transform: translateX(-120px);
                width: 89px;
            }

            &_2 {
                transform: translateX(-80px);
                bottom: 0px;
                width: 71px;
            }

            &_3 {
                bottom: 10px;
                width: 76px;
            }

        }
    }

    .invitation {
        padding: 0px 10px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, 1fr);
        gap: 10px;
        margin-bottom: 30px;

        &__block {
            border-radius: 40px;

            &_text {
                grid-column-start: 2;
                grid-column-end: 3;
                grid-row-start: 3;
                grid-row-end: 4;
            }
        }

        &__span {
            font-size: 16px;
        }

        &__title {
            font-size: 16px;
            margin-bottom: 20px;
        }

        &__paragrath {
            font-size: 12px;
        }

        &__img {
            width: auto;
            height: 50%;
            top: auto;
            bottom: 20px;
        }
    }

    .ratings {
        gap: 15px;
    }

    .question {
        margin-bottom: 30px;

        &__title {
            font-size: 28px;
            padding-bottom: 30px;
            margin-bottom: 15px;
        }

        &__hr {
            margin: 15px 0px;
        }

        summary {
            font-size: 18px;
            font-weight: 600;
        }

        p {
            font-size: 12px;
        }
    }
}

@media (max-width: 480px) {
    .infoAboutCompany {
        &__img_mobile {
            display: none;
        }
    }

    .howItWorks {
        margin-bottom: 20px;
        padding: 0px 10px;

        &__hr {
            margin-bottom: 10px;
        }

        &__bloks {
            gap: 10px;
        }

        &__img {
            display: none;
        }

        &__button {
            width: 100%;
        }
    }

    .invitation {
        padding: 0px 10px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, 1fr);
        gap: 10px;
        margin-bottom: 30px;

        &__block {
            border-radius: 40px;

            &_text {
                padding: 20px 10px;
                grid-column-start: 2;
                grid-column-end: 3;
                grid-row-start: 3;
                grid-row-end: 4;
            }
        }

        &__span {
            font-size: 16px;
        }

        &__title {
            font-size: 16px;
            margin-bottom: 10px;
        }

        &__paragrath {
            font-size: 12px;
        }

        &__img {
            display: none;
        }
    }

    .ratings {
        &__block {
            &_transparent {
                height: 50%;
                padding-top: 20px;
                margin-bottom: 20px;
            }
        }

        &__text {
            font-size: 18px;

            &_bottom {
                bottom: 1px;
                padding-top: 5px;
                width: 100%;
            }
        }

        &__qr {
            padding: 10px;
            max-width: 150px;
        }
    }

    .question {
        margin-bottom: 20px;

        &__title {
            font-size: 24px;
            padding-bottom: 10px;
            margin-bottom: 15px;
        }

        &__hr {
            margin: 10px 0px;
        }

        summary {
            font-size: 16px;
        }
    }
}