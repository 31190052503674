@mixin f ($j, $a) {
    display: flex;
    justify-content: $j;
    align-items: $a;
}


$blue: rgb(57, 83, 138);
$orange: rgb(255, 146, 46);
$orangeOpacity: rgba(255, 146, 46, 0.7);
$blue: rgb(57, 83, 138);
$orange: rgb(255, 146, 46);
$shadowBlack: rgb(32, 32, 32);
$text: rgb(44, 44, 44);
$textGray: rgb(121, 124, 136);
$iconGray: rgb(193, 198, 205);
$darkGray: rgb(149, 149, 149);
$backGray: rgb(245, 245, 245);
$backGrayOpacity: rgba(245, 245, 245, 0.5);
$backGrayOpacitMore: rgba(245, 245, 245, 0.3);
$headerWhite: rgb(253, 253, 253);
$contentWhite: rgb(255, 255, 255);
$footerBlue: rgb(33, 36, 51);

%block {
    background-color: white;
    box-shadow: 1px 3px 8px 0px $iconGray, -1px 3px 8px 0px $iconGray;
    padding: 15px;
    border-radius: 10px;
}