.catalog {
    margin-bottom: 50px;
    margin-top: 30px;

    &__title {
        font-size: 28px;
        margin-bottom: 20px;
        font-weight: 900;
    }

    &__button {
        height: 49px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgb(242, 242, 246);
        padding: 15px 70px;
        font-weight: 700;
        margin-top: 20px;
        border-radius: 10px;
        transition: background-color 0.3s, transform 0.1s, padding 0.2s;

        &:hover {
            background-color: rgba(242, 242, 246, 0.7);
            padding: 15px 100px;
        }
    }
}

.service {
    position: relative;
    background-color: rgb(242, 242, 246);
    border-radius: 10px;
    padding: 10px;
    height: 100%;
    padding-bottom: 70px;

    &__container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        align-items: flex-start;
        gap: 20px;
    }

    &__loaderBox {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        align-items: flex-start;
        gap: 20px;
    }

    &__likeIcon-box {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    &__img {
        width: 100%;
        height: 94px;
        background-color: rgb(194, 194, 197);
        border-radius: 10px;
        margin-bottom: 10px;
        object-fit: cover;

    }

    &__text {
        @include f(flex-start, flex-start);
        flex-direction: column;
    }

    &__title {
        margin-bottom: 10px;
        font-weight: 900;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: break-word;
        word-break: break-all
    }

    &__address {
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: break-word;
        word-break: break-all
    }

    &__button {
        background-color: white;
        color: rgb(27, 139, 245);
        padding: 8px 30px;
        border-radius: 30px;
        font-weight: 600;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.placeServices {
    background-image: url('../../images/other/подразместитьсвоиуслуги.png');
    background-size: 100% 100%;
    padding: 40px 70px;
    border-radius: 30px;
    background-color: rgb(1, 67, 165);
    @include f(flex-start, center);
    position: relative;
    left: 0;
    right: 0;
    width: 100%;
    margin-bottom: 50px;

    &__img {
        max-height: 330px;
    }

    &__content {
        color: white;
        margin-right: 60px;
    }

    &__title {
        font-size: 28px;
        margin-bottom: 30px;
        font-weight: 600;
    }

    &__text {
        font-size: 16px;
        margin-bottom: 25px;
        font-weight: 400;
        line-height: 30px;
    }

    &__button {
        width: 100%;
        padding: 15px 15px;
        font-weight: 700;
        border-radius: 10px;
        transition: background-color 0.3s, transform 0.1s;

        &:hover {
            background-color: $orange;
            color: white;
        }
    }
}