@media (max-width: 1024px) {
   .header {
      &__container {
         padding: 0px 15px 0px 10px
      }

      &__firstLine {
         @include f(space-between, center);
         margin-bottom: 20px;
      }
      a[href^="tel:"] {
         display: none;
      }

      &__about {
         margin-left: 0px;

         a {
            margin-right: 10px;

            &:last-child {
               margin-right: 0px;
            }
         }
      }

      &__nav-button:hover {
         position: relative;
         top: 0px;
         background-color: transparent;
      }

      &__nav-button_blue {
         background-color: $blue;
         color: white;

         &_active {
            color: $blue;
            border: 1px solid $blue;

            &:hover {
               background-color: transparent;
            }
         }

         &:hover {
            background-color: transparent;
            color: $blue;
            border: 1px solid $blue;
         }
      }

      &__nav-button_orange {
         background-color: $orange;
         color: white;

         &_active {
            color: $orange;
            border: 1px solid $orange;
         }

         &:hover {
            background-color: transparent;
            color: $orange;
            border: 1px solid $orange;
         }
      }

      &__nav-button_red {
         background-color: rgb(240, 98, 146);
         color: white;

         &_active {
            color: rgb(240, 98, 146);
            border: 1px solid rgb(240, 98, 146);
            pointer-events: none;
         }

         &:hover {
            background-color: transparent;
            color: rgb(240, 98, 146);
            border: 1px solid rgb(240, 98, 146);
         }
      }

      &__nav-button_white {
         color: white;
         background-color: rgb(124, 77, 255);

         &_active {
            color: rgb(124, 77, 255);
            border: 1px solid rgb(124, 77, 255);
            background: transparent;

            &:hover {
               background-color: transparent;
               top: 0px;
               color: rgb(124, 77, 255);
            }
         }

         &:hover {
            background-color: transparent;
            color: rgb(124, 77, 255);
         }
      }

      &__nav-button_posters {
         &:hover {
            background-color: $backGray;
         }
      }

   }

   .footer {
      &__pagesLinks {
         @include f(center, flex-start);
         gap: 20px;
         width: auto;
      }

      &__content {
         @include f(center, flex-start);
         gap: 20px;
         margin-bottom: 50px;
         padding: 0px 10px;
      }

      &__contactUs {
         @include f(flex-start, center);
         flex-direction: column;
      }

      &__contactUs-text {
         font-size: 18px;
         margin-bottom: 15px;
      }

      &__contactUs-btn {
         background-color: $blue;
         padding: 8px 15px;
         border-radius: 25px;
         border: none;
         font-size: 12px;
         transition: background-color 0.3s, transform 0.1s;

         &:hover {
            background-color: rgba($color: $blue, $alpha: 0.8);
         }
      }

      &__item {
         @include f(flex-start, flex-start);
         flex-direction: column;
         gap: 20px;

         &_apps {
            :first-child {
               margin-right: 30px;
            }
         }

         :first-child {
            margin-bottom: -5px;
         }
      }

      &__buttons-box {
         width: 234.75px;
         max-height: 100.94px;
         display: grid;
         grid-template-columns: 57% 43%;
         grid-template-rows: 1fr 1fr;
         gap: 10px;
         box-sizing: content-box;

         :first-child {
            margin-bottom: 0px;
            margin-right: 0px;
         }
      }

      &__button {
         background-color: rgb(76, 79, 95);
         border-radius: 10px;
         padding: 5px 10px;
         height: auto;
         @include f(center, center);
         transition: background-color 0.3s, transform 0.1s;

         &:hover {
            background-color: rgba($color: rgb(76, 79, 95), $alpha: 0.8);
         }

         &_big {
            padding: 10px 10px;
            grid-row: 1/3;
            grid-column: 2/3;

            &:hover {
               background-color: rgb(76, 79, 95);
            }
         }
      }

      &__hr {
         display: block;
         margin-bottom: 20px;
      }

      &__underLine {
         @include f(space-between, flex-start);
         color: rgb(99, 101, 111);

         a {
            color: rgb(99, 101, 111);
            text-decoration: underline;
         }
      }

      &__item {
         span {
            font-size: 12px;
            letter-spacing: 2px;
         }

         &_underLine {
            gap: 5px;

            a {
               font-size: 12px;
            }
         }
      }

      &__bigLetter {
         font-size: 16px;
         vertical-align: top;
      }

      &__social {
         @include f(flex-start, flex-start);

         :last-child {
            margin-right: 0px;
         }
      }

      &__social-btn {
         @include f(center, center);
         background-color: white;
         border-radius: 50%;
         height: 40px;
         width: 40px;
         margin-right: 15px;
      }
   }

   .breadCrambs {
      padding: 0px 10px;
      margin: 15px 0px 40px 0px;
   }

}

@media (max-width: 768px) {
   .breadCrambs {
      padding: 0px 10px;
      margin: 10px 0px 20px 0px;
   }

   .header {
      &__navCore {
         gap: 10px;
      }

      &__location {
         font-size: 11px;
      }

      a {
         font-size: 12px;
      }


      &__firstLine {
         @include f(space-between, center);
         margin-bottom: 20px;
      }

      &__about {
         margin-left: 0px;

         a {
            font-size: 10px;
            margin-right: 10px;
         }
      }

      &__nav-button {
         font-size: 12px;
      }

      &__nav-buttonMore {
         font-size: 12px;
      }

      margin-bottom: 15px;
      &__about {
         box-shadow: 0px 4px 10px 0px rgb(193, 198, 205);
         width: 100%;
         position: absolute;
         @include f(space-around, center);
         flex-direction: column;
         top: -300px;
         right: 0px;
         left: 0px;
         height: auto;
         padding: 20px;
         background-color: white;
         z-index: 2;
         transition: top 0.3s;

         a {
            margin-right: 0px;
            margin-bottom: 10px;
            font-size: 14px;
            &:last-child{
               margin-bottom: 0px;
            }
         }

         &_active {
            top: -10px;
         }
      }

      &__burger {
         display: block;
      }

      &__close {
         display: block;
         position: absolute;
         top: 20px;
         left: 20px;

      }

   }
}

@media (max-width: 480px) {
   .header {
      margin-bottom: 10px;

      &__firstLine {
         @include f(space-between, center);
         margin-bottom: 20px;
      }

      &__container {
         padding: 0px 10px 0px 5px
      }


      &__logo {
         display: none;
      }

      &__search-box {
         margin-left: 0px;
      }

      &__nav-button {
         font-size: 10px;
         padding: 8px 13px;
      }

      &__nav-buttonMore {
         font-size: 10px;
         padding: 8px 13px;
      }
   }

   .footer {
      a {
         font-size: 10px;

      }

      &__pagesLinks {
         width: 100%;
         @include f(center, flex-start);
         flex-wrap: wrap;
         gap: 10px;
         margin-top: 15px
      }

      &__content {
         @include f(center, center);
         flex-wrap: wrap;
         gap: 10px;
         margin-bottom: 50px;
         padding: 0px 10px;
      }

      &__contactUs {
         @include f(flex-start, center);
         flex-direction: column;
      }

      &__contactUs-text {
         font-size: 14px;
         margin-bottom: 10px;
      }

      &__contactUs-btn {
         background-color: $blue;
         padding: 8px 15px;
         border-radius: 25px;
         border: none;
         font-size: 10px;
         transition: background-color 0.3s, transform 0.1s;

         &:hover {
            background-color: rgba($color: $blue, $alpha: 0.8);
         }

      }

      &__item {
         @include f(flex-start, flex-start);
         flex-direction: column;
         gap: 10px;

         &_apps {
            :first-child {
               margin-right: 30px;
            }
         }

         :first-child {
            margin-bottom: -5px;
         }
      }

      &__buttons-box {
         width: 180px;
         display: grid;
         grid-template-columns: 57% 43%;
         grid-template-rows: 1fr 1fr;
         gap: 10px;
         box-sizing: content-box;

         :first-child {
            margin-bottom: 0px;
            margin-right: 0px;
         }
      }

      &__button {
         background-color: rgb(76, 79, 95);
         border-radius: 10px;
         padding: 5px 10px;
         height: auto;
         @include f(center, center);
         transition: background-color 0.3s, transform 0.1s;

         &:hover {
            background-color: rgba($color: rgb(76, 79, 95), $alpha: 0.8);
         }

         &_big {
            padding: 10px 10px;
            grid-row: 1/3;
            grid-column: 2/3;

            &:hover {
               background-color: rgb(76, 79, 95);
            }
         }
      }

      &__hr {
         display: block;
         margin-bottom: 10px;
      }

      &__underLine {
         @include f(space-between, flex-start);
         color: rgb(99, 101, 111);

         a {
            color: rgb(99, 101, 111);
            text-decoration: underline;
         }
      }

      &__item {
         span {
            font-size: 8px;
            letter-spacing: 2px;
         }

         &_underLine {
            gap: 5px;

            a {
               font-size: 8px;
            }
         }
      }

      &__bigLetter {
         font-size: 14px;
         vertical-align: top;
      }

      &__social {
         @include f(flex-start, flex-start);

         :last-child {
            margin-right: 0px;
         }
      }

      &__social-btn {
         @include f(center, center);
         background-color: white;
         border-radius: 50%;
         height: 35px;
         width: 35px;
         margin-right: 5px;
      }
   }

   .breadCrambs {
      &__link {
         font-size: 12px;
      }

      &__span {
         font-size: 12px;
         margin: 0px 10px;
      }
   }
}