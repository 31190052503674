.promotionPage {
    max-width: 1024px;

    &__firstLine {
        @include f(space-between, flex-start);
        width: 100%;
        margin-bottom: 20px;
        gap: 10px;
    }

    &__title {
        font-size: 20px;
        margin-bottom: 5px;
    }

    &__text {
        font-size: 14px;
        font-weight: 500;

        &_gray {
            color: $iconGray;
            display: inline;
        }

        &_p {
            max-width: 500px;
        }
    }

    &__firstLine-btnBox {
        @include f(flex-start, flex-start);
    }

    &__button {
        height: 50px;
        width: 50px;
        border-radius: 15px;
        box-shadow: 1px 3px 8px 0px $iconGray, -1px 3px 8px 0px $iconGray;
        margin-right: 10px;
        @include f(center, center);
        background-color: $backGray;
        transition: background-color 0.3s;

        &:hover {
            background-color: white;
        }
    }

    &__secondLine {
        @include f(space-between, flex-start);
        margin-bottom: 20px;
    }

    &__link {
        text-decoration: underline dotted;
        text-underline-offset: 5px;
    }

    &__img-box {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 2;
        max-width: 695px;
        background-color: $darkGray;
        max-height: 390px;
        border-radius: 16px;
        position: relative;
    }

    &__stock {
        z-index: 2;
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: $orange;
        padding: 10px;
        border-radius: 10px;
        color: white;
        border: none;
        box-shadow: none;
        font-size: 12px;
    }

    &__img {
        width: 100%;
        border-radius: 16px;
        height: 100%;
        object-fit: cover;
    }

    &__widgets {
        width: auto;
        display: grid;
        grid-template-columns: 70% 30%;
        grid-template-rows: auto;
        gap: 30px;
        position: relative;
        margin-bottom: 50px;
        padding-right: 30px;

        &:first-child {
            max-height: 150px;
        }

    }

    &__widgetsRight {
        grid-row: 1/3;
        grid-column: 2/3;
    }
}

.promoCodeModal {
    position: relative;
    max-width: 520px;
    background-color: #fff;
    border-radius: 16px;
    padding: 30px;

    &__title {
        font-size: 20px;
        margin-bottom: 20px;
    }

    &__text {
        font-size: 16px;
        margin-bottom: 20px;
    }

    &__buttons-box {
        width: 234.75px;
        max-height: 100.94px;
        display: grid;
        grid-template-columns: 57% 43%;
        grid-template-rows: 1fr 1fr;
        gap: 10px;
        box-sizing: content-box;

        :first-child {
            margin-bottom: 0px;
            margin-right: 0px;
        }
    }

    &__button {
        background-color: #F2F2F2;
        border-radius: 10px;
        padding: 5px 10px;
        height: auto;
        @include f(center, center);
        transition: background-color 0.3s, transform 0.1s;

        &:focus {
            outline: none;
        }

        &:hover {
            background-color: rgba($color: #F2F2F2, $alpha: 0.8);
        }

        &_big {
            padding: 10px 10px;
            grid-row: 1/3;
            grid-column: 2/3;

            &:hover {
                background-color: #F2F2F2;
            }
        }
    }

    &__img {
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 173px;
        height: 130px;
    }

    &__close {
        position: absolute;
        top: 5px;
        right: 15px;
        background-color: transparent;
        font-size: 30px;

        &:focus {
            outline: none !important;
        }

    }
}

.widget {
    background-color: white;
    box-shadow: 1px 3px 8px 0px $iconGray, -1px 3px 8px 0px $iconGray;
    padding: 15px;
    border-radius: 10px;
    position: relative;

    &_contact {
        margin-bottom: 30px;

        a {
            max-width: 100%;
            word-wrap: break-word;
        }
    }

    &__promoCodeBtn {
        position: relative;
        left: 100%;
        margin-top: 20px;
        transform: translateX(-105%);
        border-radius: 15px;
        box-shadow: 1px 3px 8px 0px $iconGray, -1px 3px 8px 0px $iconGray;
        transition: background-color 0.3s;
        width: auto;
        height: auto;
        background-color: $orange;
        padding: 16px 25px;
        color: white;

        &:hover {
            background-color: $orangeOpacity;
        }

    }

    &__conditions {
        display: block;
    }

    &__title {
        font-size: 16px;
        margin-bottom: 20px
    }

    &__content {
        margin-bottom: 20px;

        & div:nth-of-type(2) {
            margin-top: 10px;
        }
    }

    &__text-box {
        @include f(flex-start, flex-start);
        flex-direction: column;
        margin-right: 20px;
    }

    &__text {
        &_gray {
            color: $iconGray;
            margin-bottom: 10px;
        }
    }

    &__item {
        @include f(flex-start, center);
        gap: 10px;
        margin-bottom: 20px;

        &_share {
            display: flex;
            justify-content: space-between;
            gap: 0px;
        }

        &_address {
            position: relative;
        }

        &_big {
            @include f(flex-start, flex-start);
            margin-bottom: 0px;
        }

        &_border {
            padding-bottom: 10px;
            border-bottom: 1px solid rgb(242, 242, 244);
        }

        &-btn {
            height: 34px;
            min-width: 34px;
            border-radius: 10px;
            background-color: rgb(245, 245, 245);
            @include f(center, center);
            transition: border 0.2s;

            &_selected {
                border: 2px solid black;
            }

            &_vk {
                background-color: rgb(70, 128, 194);
                @include f(center, center);
            }

            &_telegram {
                background-color: rgb(68, 166, 255);
                @include f(center, center);
            }

            &_viber {
                background-color: rgb(116, 96, 242);
                @include f(center, center);
            }

            &_ok {
                background-color: rgb(238, 130, 8);
                @include f(center, center);
            }

            &_mailru {
                background-color: rgb(1, 94, 249);
                @include f(center, center);
            }

            &_big {
                height: 40px;
                width: 100%;
                border-radius: 10px;
                background-color: rgb(245, 245, 245);
                transition: background-color 0.3s, transform 0.1s;

                &:hover {
                    background-color: $orange;
                    color: white;
                }
            }
        }
    }

    &__item-text {
        width: auto;
        font-weight: 500;
        word-wrap: break-word;

        a {
            overflow-wrap: break-word;
            word-break: break-all;
        }

        &_green {
            user-select: none;
            color: green;
        }

        &_red {
            color: rgb(244, 67, 54);
        }

        &_orange {
            color: rgb(255, 171, 0);
        }

        &_big {
            @include f(flex-start, flex-start);
            flex-direction: column;
        }

        &_linkOnMap {
            cursor: pointer;
            color: blue;
            position: absolute;
            bottom: -3px;
            right: 0;
        }
    }
}

.widget-info {
    grid-column-start: 1;
    grid-column-end: 2;
    background-color: white;
    box-shadow: 1px 3px 8px 0px $iconGray, -1px 3px 8px 0px $iconGray;
    padding: 15px;
    border-radius: 10px;
    max-width: 695px;
    position: relative;

    &__link {
        font-size: 18px;
        margin-right: 50px;
        display: inline-block;
        padding-bottom: 20px;
        font-weight: 450;
        cursor: pointer;

        &_active {
            font-weight: 700;
            border-bottom: 1px solid $orange;
        }
    }

    &__hr {
        border: 1px solid rgb(242, 242, 244);
        color: rgb(242, 242, 244);
        margin-top: -1px;
    }

    &__content {
        margin-top: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
    }
}

.map {
    width: 100vw;
    height: 600px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 50px;

    &__content {
        pointer-events: all;
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translateY(-50%);
        background-color: white;
        box-shadow: 1px 3px 8px 0px $iconGray, -1px 3px 8px 0px $iconGray;
        border-radius: 16px;
    }

    &__btnBox {
        margin: 15px 0px;
        padding: 0px 15px;
        width: 100%;
    }

    &__button {
        padding: 10px 30px;
        background-color: $blue;
        border-radius: 10px;
        color: white;
        width: 100%;
        transition: background-color 0.3s, transform 0.1s;

        &:hover {
            background-color: rgba(57, 83, 138, 0.7);
        }
    }

    &__title {
        font-size: 16px;
        margin: 15px;
        margin-bottom: 20px;
        max-width: 280px;
    }

    &__item {
        margin: 15px;
        @include f(flex-start, center);
        gap: 10px;
        margin-bottom: 20px;
    }

    &__hr {
        border: 1px solid rgb(242, 242, 244);
        margin-bottom: 20px;
        position: relative;
        width: 100%;
    }

    &__item-btn {
        height: 40px;
        min-width: 40px;
        border-radius: 10px;
        background-color: rgb(245, 245, 245);
        margin-right: 3px;
        @include f(center, center);
    }

    &__item-text {
        font-weight: 500;
        word-wrap: break-word;

        &_green {
            color: green;
        }
    }
}

.discountPage {
    max-width: 1024px;

    &__content {
        display: grid;
        gap: 20px;
        grid-template-rows: 450px;
        grid-template-columns: 255px 1fr;
        margin-bottom: 20px
    }

    &__info {
        background-color: white;
        box-shadow: 1px 3px 8px 0px $iconGray, -1px 3px 8px 0px $iconGray;
        padding: 30px;
        border-radius: 10px;
        position: relative;
    }

    &__shortDescription {
        margin-bottom: 30px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__descriptionTitle {
        font-size: 20px;
        margin-bottom: 10px;

    }

    .discountPage__descriptionBlock {
        position: relative;
    }

    .discountPage__description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: max-height 0.3s ease;
    }

    .discountPage__description_extended {
        display: block;
        -webkit-line-clamp: unset;
        max-height: none;
    }

    .discountPage__descriptionBlock {
        max-height: 220px;
        overflow-y: auto;
        padding-right: 5px;

        &::-webkit-scrollbar {
            background-color: transparent;
            width: 5px;
            border-left: none;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
        }

        padding-bottom: 25px;
    }

    &__descriptionMoreLink {
        position: absolute;
        right: 0px;
        bottom: 0px;
        color: $orange;
        font-weight: 600;
        cursor: pointer;
    }

    &__title {
        font-size: 24px;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__text {
        font-size: 14px;
        font-weight: 500;

        &_gray {
            color: $iconGray;
            display: inline;
        }

        &_p {
            max-width: 500px;
        }
    }

    &__info-btnBox {
        @include f(flex-end, center);
        position: absolute;
        bottom: 30px;
        right: 30px;
    }

    &__button {
        height: 50px;
        width: 50px;
        border-radius: 15px;
        box-shadow: 1px 3px 8px 0px $iconGray, -1px 3px 8px 0px $iconGray;
        margin-left: 10px;
        @include f(center, center);
        background-color: $backGray;
        transition: background-color 0.3s;

        &:hover {
            background-color: white;
        }

        &_big {
            width: auto;
            height: auto;
            margin-right: 0;
            background-color: $orange;

            a {
                padding: 16px 25px;
                color: black;
            }

            &:hover {
                background-color: $orangeOpacity;
            }
        }
    }

    &__secondLine {
        @include f(space-between, flex-start);
        margin-bottom: 20px;
    }

    &__link {
        text-decoration: underline dotted;
        text-underline-offset: 5px;
    }

    &__img-box {
        background-color: $darkGray;
        border-radius: 16px;
        position: relative;
    }

    &__stock {
        z-index: 2;
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: $orange;
        padding: 10px;
        border-radius: 10px;
        color: white;
        border: none;
        box-shadow: none;
        font-size: 12px;
    }

    &__img {
        width: 100%;
        border-radius: 16px;
        height: 100%;
        object-fit: cover;
    }

    &__widgets {
        width: auto;
        display: grid;
        grid-template-columns: 70% 30%;
        grid-template-rows: auto;
        gap: 30px;
        position: relative;
        margin-bottom: 50px;
        padding-right: 30px;

        &:first-child {
            max-height: 150px;
        }

    }

    &__widgetsRight {
        grid-row: 1/3;
        grid-column: 2/3;
    }
}