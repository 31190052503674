@media (max-width: 1024px) {
    .placeServices {
        width: 100%;

        &__container {
            padding: 0px 10px;
        }
    }

    .service {
        height: 100%;

        &__container {
            grid-template-columns: repeat(5, minmax(100px, 1fr));
            gap: 15px;
        }

        &__loaderBox {
            grid-template-columns: repeat(5, minmax(100px, 1fr));
            gap: 15px;
        }

        &__content {
            margin-bottom: 0px;
        }

        &__title {
            font-size: 12px;
            margin-bottom: 10px;
        }

        &__address {
            font-size: 12px;
        }

        &__bottom {
            position: absolute;
            bottom: 10px;
            right: 10px;
        }

        &__button {
            font-size: 12px;

        }
    }

    .catalog {
        margin-bottom: 30px;
        padding: 0px 10px;
        margin-top: 20px;

        &__title {
            font-size: 22px;
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 768px) {
    .placeServices {
        padding: 20px 35px;
        border-radius: 30px;
        margin-bottom: 30px;

        &__img {
            display: none;
        }

        &__title {
            font-size: 22px;
            margin-bottom: 20px;
        }

        &__text {
            font-size: 14px;
            margin-bottom: 10px;
            line-height: 25px;
        }

        &__button {
            padding: 10px 10px;
            border-radius: 10px;
            transition: background-color 0.3s, transform 0.1s;
            font-size: 12px;
        }
    }

    .service {
        height: 100%;

        &__container {
            grid-template-columns: repeat(3, minmax(100px, 1fr));
            gap: 15px;
        }

        &__loaderBox {
            grid-template-columns: repeat(3, minmax(100px, 1fr));
            gap: 15px;
        }

        &__img {
            height: 123px;
        }
    }

    .catalog {
        margin-top: 15px;
    }

}

@media (max-width: 480px) {
    .service {
        height: 100%;

        &__container {
            grid-template-columns: repeat(1, minmax(100px, 1fr));
            gap: 15px;
        }

        &__loaderBox {
            grid-template-columns: repeat(1, minmax(100px, 1fr));
            gap: 15px;
        }

        &__img {
            height: 245px;
        }
    }

    .catalog {
        margin-top: 10px;
    }
}