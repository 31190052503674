@media (max-width: 1024px) {
    .infoAboutApp {
        &__container {
            padding: 0px 10px;
        }

        &__img {
            display: none;
        }
    }

    .infoPartnership {
        padding: 30px 0px;

        &__title {
            padding: 0px 10px;
            font-size: 38px;
            margin-bottom: 20px;
        }

        &__content-box {
            padding: 0px 10px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 10px;
        }

        &__item {
            flex-grow: 1;
            padding: 15px 15px;
        }

        &__item-title {
            margin-bottom: 10px;
            font-size: 20px;
        }

        &__item-text {
            font-size: 14px;

            &_marginTop {
                margin-top: 0px;
            }
        }
    }

    .usPartners {
        margin: 50px 0px;
        padding: 0px 10px;

        &__icons-box {
            grid-template-columns: repeat(5, minmax(100px, 1fr));
            grid-template-rows: repeat(2, minmax(100px, 1fr));
            gap: 10px;
        }

        &__icon {
            height: auto;
            border-radius: 30px;
        }

        &__more-icon {
            border-radius: 30px;
            padding: 15px;
            height: auto;

            span {
                font-size: 16px;
            }
        }
    }

    .becomePartner {
        @include f(center, center);
        padding: 40px 10px;

        &__img {
            height: 220px;
        }

        &__text {
            font-size: 36px;

            &_bold {
                font-weight: 700;
                font-size: 32px;
            }
        }

        &__button {
            margin-top: 20px;
            padding: 10px 20px;
            font-size: 16px;
        }
    }
}

@media (max-width: 768px) {
    .partnership {
        padding-top: 30px;

        &__title,
        h2 {
            font-size: 24px;
            margin-bottom: 15px;
        }

        &__title,
        h3 {
            word-break: keep-all;
            font-size: 16px;
            margin-top: 10px;
        }

        &__content {
            padding: 0px 10px 0px 10px;
            @include f(center, flex-start);
            gap: 20px
        }

        &__form {
            width: 80%;
            padding: 15px;
            margin-bottom: 60px;
        }

        &__label {
            margin: 20px 0px 10px 0px;
        }

        &__formBtn {
            padding: 10px 15px;
            width: 100%;
            margin-top: 20px;
            font-size: 14px
        }

        &__contacts {
            span:nth-child(even) {
                margin: 15px 0px 10px 0px;
                font-size: 16px;
            }
        }

        &__contact,
        a {
            font-size: 12px
        }
    }

    .infoAboutApp {
        &__container {
            margin-bottom: 50px;
            margin-top: 25px;
        }

        &__title {
            font-size: 30px;
            width: auto;
        }

        &__text {
            width: auto;
            margin: 15px 0px;
            font-size: 14px;
        }

        &__button {
            padding: 10px 50px;
            font-size: 14px
        }

        &__img {
            display: none;
        }
    }

    .infoPartnership {
        padding: 20px 0px;

        &__title {
            padding: 0px 10px;
            font-size: 24px;
            margin-bottom: 10px;
        }

        &__content-box {
            padding: 0px 10px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 10px;
        }

        &__item {
            flex-grow: 1;
            padding: 15px 15px;
        }

        &__item-title {
            margin-bottom: 5px;
            font-size: 16px;
        }

        &__item-text {
            font-size: 12px;

            &_marginTop {
                margin-top: 0px;
            }
        }
    }

    .usPartners {
        margin: 30px 0px;

        &__title {
            font-size: 28px;
            margin-bottom: 10px;
        }

        &__icons-box {
            grid-template-columns: repeat(4, minmax(100px, 1fr));
            grid-template-rows: repeat(3, minmax(100px, 1fr));
        }

        &__more-icon {
            padding: 10px;

            span {
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 480px) {
    .partnership {
        &__form {
            width: 100%;
        }

        &__contacts {
            display: none;

            span:nth-child(even) {
                margin: 15px 0px 10px 0px;
                font-size: 16px;
            }
        }
    }

    .usPartners {
        margin: 30px 0px;

        &__title {
            font-size: 28px;
        }

        &__icons-box {
            grid-template-columns: repeat(3, minmax(100px, 1fr));
            grid-template-rows: repeat(4, minmax(100px, 1fr));
        }
    }

    .becomePartner {
        @include f(center, center);
        padding: 20px 10px;

        &__img {
            display: none;
        }

        &__text {
            font-size: 32px;

            &_bold {
                font-weight: 700;
                font-size: 26px;
            }
        }

        &__button {
            margin-top: 10px;
            padding: 10px 10px;
            font-size: 12px;
        }
    }
}