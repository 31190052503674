.ymaps-2-1-79-gototech {
   display: none !important;
}

.ymaps-2-1-79-copyright__link {
   display: none !important;
}

:root {
   --swiper-theme-color: rgb(255, 146, 46) !important;
}

.fancybox__content {
   background-color: transparent !important;
   padding: 0px !important;
}

.f-button {
   display: none !important;
}