::-webkit-scrollbar {
   background-color: transparent;
   width: 10px;
   border-left: 1px solid #5A5A5A;
}

::-webkit-scrollbar-thumb {
   background-color: $orange;
   height: 5px;
   border: 1px solid #5A5A5A;
}

* {
   -webkit-tap-highlight-color: transparent;
   user-select: none;
}

p {
   white-space: pre-wrap;
   user-select: text;
}

span {
   white-space: pre-wrap;
}

#root {
   display: flex;
   flex-direction: column;
   min-height: 100vh;
   margin: 0;
}

main {
   flex-grow: 1;
}

footer {
   margin-top: auto;
}

a {
   user-select: none;
   color: $textGray;
   text-decoration: none;
   font-size: 14px;
   cursor: pointer;
}

button {
   cursor: pointer;
   border: none;
}

html {
   overflow-x: hidden;
}

body {
   font-size: 14px;
   min-width: 360px;
   font-family: 'Segoe UI';
   color: $text;
   font-weight: 400;
   word-wrap: break-word;
   line-height: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: 'Segoe UI';
}

.container {
   width: 1024px;
   max-width: 100vw;
   margin: 0 auto;
   min-width: 360px;
}

.container-fluid {
   max-width: 1920px;
   margin: 0 auto;
   padding: 0 10px;
}